import { APP_INSTALLER_HEIGHT, HEADER_HEIGHT, Paths } from "@constants";
import useTypedSelector from "@hooks/useTypedSelector";
import { useMemo } from "react";
import { Toaster as RawToaster } from "react-hot-toast";
import { matchPath, useLocation } from "react-router-dom";

const NONE_HEADER_PATHS = [
  Paths.PRIVATE.UPDATE_BANK_FORCE,
  Paths.PRIVATE.UPDATE_PIN_FORCE,
  Paths.PRIVATE.DEPOSIT_CALLBACK,
  ...Object.values(Paths.PUBLIC),
];

const Toaster = () => {
  const pathname = useLocation().pathname;
  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);

  const hasHeader = useMemo(() => {
    return !NONE_HEADER_PATHS.some((route) => matchPath(route, pathname));
  }, [pathname]);

  const topOffset = useMemo(() => {
    let baseOffset = 0;
    if (isDisplayAppInstaller) baseOffset += APP_INSTALLER_HEIGHT;
    if (hasHeader) baseOffset += HEADER_HEIGHT;
    return baseOffset + "px";
  }, [hasHeader, isDisplayAppInstaller]);

  return (
    <RawToaster
      toastOptions={{
        duration: 3000,
        style: {
          color: "white",
          borderRadius: "6px",
          fontSize: "14px",
          padding: "4px 8px 4px 8px",
          alignItems: "center",
        },
        className: "custom-toast !bg-black/90 border border-border-transparent",
      }}
      containerStyle={{
        marginTop: topOffset,
      }}
      containerClassName="transition-[margin-top] ease-in-out duration-300"
    />
  );
};

export default Toaster;
