import { PreloadableComponent } from "@utils/lazyWithPreload";
import { ReactNode, Suspense, useEffect, useState } from "react";

export function LazyLoadComponent({
  children,
  fallback = null,
  preload,
  preloadDelay = 0,
  delay = 0,
}: {
  children: React.ReactNode;
  preload?: PreloadableComponent<any>[];
  fallback?: ReactNode;
  preloadDelay?: number;
  delay?: number;
}): JSX.Element {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (preload) {
      setTimeout(() => {
        preload.forEach((component) => {
          component.preload();
        });
      }, preloadDelay);
    }
  }, [preload, preloadDelay]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (delay === 0) {
      setIsReady(true);
      return;
    } else {
      timer = setTimeout(() => {
        setIsReady(true);
      }, delay);
    }

    return () => clearTimeout(timer);
  }, [delay]);

  return <Suspense fallback={fallback}>{isReady ? children : null}</Suspense>;
}
