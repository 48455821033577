import { RootState } from "@stores";
import { isTokenExpired } from "@utils/jwt";
import logger from "@utils/logger";
import { getWsUrl } from "@utils/websocket";
import { APIErrorCodes, AuthToken, WSEventResponse } from "kz-ui-sdk";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";

export default function useAppWS() {
  const auth = useSelector<RootState, AuthToken | null | undefined>((state) => state?.auth?.oauth);

  const token: string | null = useMemo(() => {
    if (auth?.access_token) {
      // Check if token is expired
      if (isTokenExpired(auth.access_token)) {
        logger._console.log("WS token expired");
        return null;
      }
      return auth.access_token;
    }
    return null;
  }, [auth?.access_token]);

  return useWebSocket<WSEventResponse>(token ? getWsUrl(token) : null, {
    share: true,
    onClose: (e) => {
      logger._console.error("WS close", e);
    },
    onError: (e) => {
      logger._console.error("WS error", e);
    },
    shouldReconnect: (e) => {
      // Do not reconnect if the connection is closed normally or token is expired
      if (e.code === 1000 || e.code === 1001 || e?.reason === APIErrorCodes.Auth.TokenExpired) {
        return false;
      }
      return true;
    },
    reconnectAttempts: 5,
    reconnectInterval: (attemptNumber) => {
      logger._console.log("WS reconnecting attempt number", attemptNumber);
      return attemptNumber * 30000;
    }, // each 30 seconds
    heartbeat: {
      message: "ping",
      interval: 45000, // 45 seconds
      // Server should return "pong" to keep the connection before timeout
      // For now, we don't have a server-side implementation for this
      // So FE keeps timeout to 24 hours to extend the connection
      timeout: 24 * 60 * 60 * 1000, // 24 hours
    },
  });
}
