import PrivateRouteLayout from "@components/layouts/PrivateRouteLayout";
import PublicRouteLayout from "@components/layouts/PublicRouteLayout";
import RootRouteLayout from "@components/layouts/RootRouteLayout";
import WSRouteLayout from "@components/layouts/WSRouteLayout";
import { LazyLoadComponent } from "@components/utils/LazyLoadComponent";
import MarketingDataCollector from "@components/utils/MarketingDataCollector";
import Toaster from "@components/utils/Toaster";
import { Paths } from "@constants";
import { UserMode } from "@types";
import { lazyWithPreload } from "@utils/lazyWithPreload";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const LazyHomePage = lazyWithPreload(() => import("@views/HomePage"));
const LazyPrizesPage = lazyWithPreload(() => import("@views/PrizesPage"));
const LazyProfilePage = lazyWithPreload(() => import("@views/ProfilePage"));
const LazyDailyRewardPage = lazyWithPreload(() => import("@views/DailyRewardPage"));
const LazyCashbackPage = lazyWithPreload(() => import("@views/CashbackPage"));
const LazyTransactionPage = lazyWithPreload(() => import("@views/TransactionPage"));
const LazyLanguagePage = lazyWithPreload(() => import("@views/LanguagePage"));
const LazyBetHistoryPage = lazyWithPreload(() => import("@views/BetHistoryPage"));
const LazyUpdatePINPage = lazyWithPreload(() => import("@views/UpdatePINPage"));
const LazyBankAccountsPage = lazyWithPreload(() => import("@views/BankAccountsPage"));
const LazyOnboardResetPINPage = lazyWithPreload(() => import("@views/OnboardResetPINPage"));
const LazyTermsConditionsPage = lazyWithPreload(() => import("@views/TNCPage"));
const LazyReferralPage = lazyWithPreload(() => import("@views/ReferralPage"));
const DepositCallbackPage = lazyWithPreload(() => import("@views/DepositCallbackPage"));
const LazyOnboardUpdateBankPage = lazyWithPreload(() => import("@views/OnboardUpdateBankPage"));

const LazyOnboardPage = lazyWithPreload(() => import("@views/OnboardingPage"));
const LazyLoginPage = lazyWithPreload(() => import("@views/LoginPage"));
const LazyRegisterPage = lazyWithPreload(() => import("@views/RegisterPage"));
const LazyForgotPINPage = lazyWithPreload(() => import("@views/ForgotPINPage"));
const LazyReferralCallbackPage = lazyWithPreload(() => import("@views/ReferralCallbackPage"));
const LazyMaintenancePage = lazyWithPreload(() => import("@views/MaintenancePage"));

// Improve LCP by preloading the home page
LazyHomePage.preload();

const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Toaster />
        <MarketingDataCollector />

        <RootRouteLayout>
          <Routes>
            <Route element={<WSRouteLayout />}>
              {/*GUEST ROUTE*/}
              <Route element={<PrivateRouteLayout mode={UserMode.GUEST} />}>
                <Route
                  path={Paths.PRIVATE.HOME}
                  element={
                    <LazyLoadComponent
                      preloadDelay={500}
                    preload={[LazyPrizesPage, LazyCashbackPage, LazyDailyRewardPage, LazyReferralPage]}
                    >
                      <LazyHomePage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.PRIZES}
                  element={
                    <LazyLoadComponent>
                      <LazyPrizesPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.CASHBACK}
                  element={
                    <LazyLoadComponent>
                      <LazyCashbackPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.DAILY_REWARD}
                  element={
                    <LazyLoadComponent>
                      <LazyDailyRewardPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.REFERRAL}
                  element={
                    <LazyLoadComponent>
                      <LazyReferralPage />
                    </LazyLoadComponent>
                  }
                />
              </Route>

              {/*PRIVATE ROUTES*/}
              {/*PrivateRouteLayout is a layout that checks if the user is authenticated before rendering the children */}
              {/*The user is redirected to the home page if not authenticated*/}
              <Route element={<PrivateRouteLayout mode={UserMode.MEMBER} />}>
                <Route
                  path={Paths.PRIVATE.PROFILE}
                  element={
                    <LazyLoadComponent>
                      <LazyProfilePage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.TRANSACTION}
                  element={
                    <LazyLoadComponent>
                      <LazyTransactionPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.LANGUAGE}
                  element={
                    <LazyLoadComponent>
                      <LazyLanguagePage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.BET_HISTORY}
                  element={
                    <LazyLoadComponent>
                      <LazyBetHistoryPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.UPDATE_BANK_FORCE}
                  element={
                    <LazyLoadComponent>
                      <LazyOnboardUpdateBankPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.UPDATE_PIN_FORCE}
                  element={
                    <LazyLoadComponent>
                      <LazyOnboardResetPINPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.UPDATE_PIN}
                  element={
                    <LazyLoadComponent>
                      <LazyUpdatePINPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.BANK_ACCOUNTS}
                  element={
                    <LazyLoadComponent>
                      <LazyBankAccountsPage />
                    </LazyLoadComponent>
                  }
                />
                <Route
                  path={Paths.PRIVATE.TERMS_CONDITIONS}
                  element={
                    <LazyLoadComponent>
                      <LazyTermsConditionsPage />
                    </LazyLoadComponent>
                  }
                />
              </Route>
            </Route>
            <Route
              path={Paths.PRIVATE.DEPOSIT_CALLBACK}
              element={
                <LazyLoadComponent>
                  <DepositCallbackPage />
                </LazyLoadComponent>
              }
            />

            {/*PUBLIC ROUTES*/}
            <Route element={<PublicRouteLayout />}>
              <Route
                path={Paths.PUBLIC.ONBOARD_LOGIN}
                element={
                  <LazyLoadComponent
                    preloadDelay={500}
                    preload={[LazyHomePage]}
                  >
                    <LazyOnboardPage />
                  </LazyLoadComponent>
                }
              />
              <Route
                path={Paths.PUBLIC.ONBOARD}
                element={
                  <LazyLoadComponent
                    preloadDelay={500}
                    preload={[LazyHomePage]}
                  >
                    <LazyOnboardPage />
                  </LazyLoadComponent>
                }
              />

              <Route
                path={Paths.PUBLIC.LOGIN}
                element={
                  <LazyLoadComponent
                    preloadDelay={500}
                    preload={[LazyHomePage]}
                  >
                    <LazyLoginPage />
                  </LazyLoadComponent>
                }
              />
              <Route
                path={Paths.PUBLIC.REGISTER}
                element={
                  <LazyLoadComponent>
                    <LazyRegisterPage />
                  </LazyLoadComponent>
                }
              />
              <Route
                path={Paths.PUBLIC.FORGOT_PIN}
                element={
                  <LazyLoadComponent>
                    <LazyForgotPINPage />
                  </LazyLoadComponent>
                }
              />
              <Route
                path={Paths.PUBLIC.REFERRAL_CALLBACK}
                element={
                  <LazyLoadComponent>
                    <LazyReferralCallbackPage />
                  </LazyLoadComponent>
                }
              />

              <Route
                path={Paths.PUBLIC.MAINTENANCE}
                element={
                  <LazyLoadComponent>
                    <LazyMaintenancePage />
                  </LazyLoadComponent>
                }
              />
            </Route>

            {/* 404 Route - Catch all unknown routes */}
            <Route
              path="*"
              element={
                <Navigate
                  to={Paths.PRIVATE.HOME}
                  replace
                />
              }
            />
          </Routes>
        </RootRouteLayout>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
