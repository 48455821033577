import { IconSparkle } from "@assets";
import BrandLogo from "@components/data-display/BrandLogo";
import ContactButton from "@components/inputs/ContactButton";
import { LazyLoadComponent } from "@components/utils/LazyLoadComponent";
import { openOnboardingDrawer } from "@components/utils/OnboardingDrawer/utils";
import UserModeRenderer from "@components/utils/UserModeRenderer";
import { BALANCE_BADGE_TOP_ID, HEADER_HEIGHT, OnboardMode, Paths } from "@constants";
import useServerInfo from "@hooks/useServerInfo";
import useTypedSelector from "@hooks/useTypedSelector";
import { UserMode } from "@types";
import { Button, PageHeader as KZPageHeader, PropsWithClassName, cn } from "kz-ui-sdk";
import { lazy, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";
import "./PageHeader.css";

interface PageHeaderProps extends PropsWithClassName {}

const deferredUpdateBalancePaths = [Paths.PRIVATE.CASHBACK, Paths.PRIVATE.REFERRAL];
const skipUpdateBalancePaths = [Paths.PRIVATE.DAILY_REWARD];

const LazyBalanceBadge = lazy(() => import(/* webpackChunkName: "BalanceBadge" */ "@components/utils/BalanceBadge"));

export default function PageHeader({ className }: PageHeaderProps) {
  const { t } = useTranslation();
  const pathname = useLocation().pathname;
  const { contactLink: preLoginContactLink } = useServerInfo() ?? {};
  const { contactLink: postLoginContactLink } = useTypedSelector((state) => state.auth);

  const { delayUpdate, skipUpdate } = useMemo(() => {
    let delayUpdate: number | undefined;
    let skipUpdate: boolean | undefined;
    // In daily reward page, we need to skip the update of balance badge
    if (skipUpdateBalancePaths.some((path) => matchPath(path, pathname))) {
      skipUpdate = true;
    }
    // In cashback/refer page, we need to delay the update of balance badge
    if (deferredUpdateBalancePaths.some((path) => matchPath(path, pathname))) {
      delayUpdate = 1200;
    }
    return {
      delayUpdate,
      skipUpdate,
    };
  }, [pathname]);

  //#region contact links
  const contactLinks = useMemo(() => {
    return [postLoginContactLink || preLoginContactLink].filter(Boolean) as string[];
  }, [preLoginContactLink, postLoginContactLink]);

  const renderContactLinks = useMemo(() => {
    const links = [];

    for (const link of contactLinks) {
      links.push(
        <ContactButton
          key={link}
          url={link}
        />,
      );
    }

    return links;
  }, [contactLinks]);
  //#endregion

  return (
    <KZPageHeader
      fixed
      className={cn("z-10", className)}
    >
      <div
        className="mx-auto flex max-w-md flex-row items-center justify-between border-b border-white/10 bg-white/5 bg-header px-3 py-3"
        style={{
          height: HEADER_HEIGHT,
        }}
      >
        {/*LOGO*/}
        <div className="h-8 w-auto px-1.5">
          <BrandLogo
            size="auto"
            navigateTo={Paths.PRIVATE.HOME}
          />
        </div>

        {/*CONTACT LINKS*/}
        <div className="mx-3 ml-auto flex gap-1">{renderContactLinks}</div>

        {/*MEMBER MODE*/}
        <UserModeRenderer mode={UserMode.MEMBER}>
          {/*BALANCE BADGE*/}
          <LazyLoadComponent>
            <LazyBalanceBadge
              id={BALANCE_BADGE_TOP_ID}
              delayUpdate={delayUpdate}
              skipAutoUpdate={skipUpdate}
            />
          </LazyLoadComponent>
        </UserModeRenderer>

        {/*OPEN MODE*/}
        <UserModeRenderer mode={UserMode.GUEST}>
          {/*LOGIN*/}
          <Button
            size={"sm"}
            variant={"bordered"}
            className="ml-1 h-[30px] !w-fit !border px-2"
            onClick={() => {
              openOnboardingDrawer({
                mode: OnboardMode.LOGIN,
              });
            }}
          >
            {t("Login")}
          </Button>
          {/*REGISTER*/}
          <span className="relative ml-1 inline-block h-[30px]">
            <Button
              size={"sm"}
              variant={"primary"}
              className="h-[30px] !w-fit px-2"
              shiny
              onClick={() => {
                openOnboardingDrawer({
                  mode: OnboardMode.REGISTER,
                });
              }}
            >
              {t("Register")}
            </Button>
            <IconSparkle className="page-header__register-sparkle absolute -right-1.5 -top-1.5" />
          </span>
        </UserModeRenderer>
      </div>
    </KZPageHeader>
  );
}
