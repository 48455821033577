import { deployEnvConfig } from "@configs/deploy-env";
import { setSentryMember } from "@configs/sentry/sentry";
import { STORAGE_KEY } from "@constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import logger from "@utils/logger";
import {
  AccountModel,
  AuthToken,
  BankConfig,
  bnOrZero,
  localizePhoneNumber,
  ProfileResponse,
  SelfEntityModel,
} from "kz-ui-sdk";
import { InitAuthPayload } from "./types";

export type AuthSliceState = {
  oauth?: AuthToken | null;
  self?: SelfEntityModel | null;
  bankInfos?: Record<string, BankConfig> | null;
  accounts?: AccountModel[] | null;
  contactLink?: string | null;
  latestDepositAmount?: number;
};

const persistToken = (token: AuthToken | null) => {
  logger._console.log("persist token", token);
  if (token?.access_token) localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, token.access_token);
  else localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
};

const clearOnboardStorage = (phone: string) => {
  localStorage.removeItem(STORAGE_KEY.OTP_TIMEOUT_PREFIX + phone);
  sessionStorage.removeItem(STORAGE_KEY.ONBOARDING_PHONE);
};

const persistLoginPhone = (phone: string) => {
  localStorage.setItem(STORAGE_KEY.LOGIN_PHONE, phone);
};

const generateSelf = (data: SelfEntityModel | undefined): SelfEntityModel | null => {
  const self = data ? { ...data } : null;
  if (self?.member?.phone) {
    const localPhone = localizePhoneNumber(self.member.phone, deployEnvConfig.country.phoneCode);
    // Remember phone number for login
    persistLoginPhone(localPhone);
    // Clear onboard storage
    clearOnboardStorage(self?.member?.phone);
  }
  return self;
};

export const initialState: AuthSliceState = {};

export const authSlice = createSlice({
  name: "authSlices",
  initialState,
  reducers: {
    initAuthState(state, action: PayloadAction<InitAuthPayload | null>) {
      const { token = null, accounts = null } = action.payload ?? {};
      const self = generateSelf(action.payload?.self);
      state.oauth = token;
      state.self = self;
      state.bankInfos = action.payload?.bankInfos;
      state.contactLink = action.payload?.contactLink;
      state.latestDepositAmount = action.payload?.latestDepositAmount;
      persistToken(token);
      setSentryMember(self?.member);
    },
    updateAccessToken(state, action: PayloadAction<AuthToken | null>) {
      logger._console.log("update access token", action);
      state.oauth = action.payload;
      persistToken(action.payload);
    },
    resetExpiredAt(state) {
      if (state?.self?.member?.pwExpireAt) {
        state.self.member.pwExpireAt = null;
      }
    },
    updateProfile(state, action: PayloadAction<ProfileResponse | null>) {
      const self = generateSelf(action.payload?.self);
      state.accounts = action.payload?.accounts ?? null;
      state.self = self;
      state.contactLink = action.payload?.contactLink ?? "";
      state.bankInfos = action.payload?.bankInfos;
      state.latestDepositAmount = action.payload?.latestDepositAmount;
      setSentryMember(self?.member);
    },
    updateProfileBalance(state, action: PayloadAction<string>) {
      // update state.self.member.primaryWallet.balance with new balance
      if (state.self?.member?.primaryWallet) {
        state.self.member.primaryWallet.balance = bnOrZero(action.payload);
      }
    },
    logout(state, action: PayloadAction<void>) {
      state.oauth = null;
      state.self = null;
      state.accounts = null;
      state.contactLink = null;
      setSentryMember(null);
      persistToken(null);
    },
  },
});

export default authSlice;
