import { RewardInfoData } from "@hooks/useGetRewardsInfo";
import { RewardStatus } from "@types";
import { bnOrZero, WalletStatus, WalletTxMethod } from "kz-ui-sdk";
import moment from "moment";

/**
 * rule: [current <  now  < next]
 * did claim ?
 *  - no  -> claimable
 *  - yes -> claimedAt in range (current, next) ?
 *        - no  -> claimable
 *        - yes -> show count down
 */
export const getDiff = (rewardInfo: WalletStatus | undefined, offset: number) => {
  const lastClaimedAt = rewardInfo?.lastClaimedAt ?? null;
  const currentPeriodResetDate = rewardInfo?.currentPeriodResetDate ?? null;
  const nextPeriodResetDate = rewardInfo?.nextPeriodResetDate ?? null;

  if (lastClaimedAt === null) {
    return 0;
  }
  if (moment(lastClaimedAt).isBefore(moment(currentPeriodResetDate))) {
    return 0;
  }

  const now = moment();
  const target = moment.utc(nextPeriodResetDate).utcOffset(offset);

  return Math.ceil(target.diff(now, "seconds"));
};

export const checkClaimable = (data: Record<WalletTxMethod, RewardInfoData | null> | null) => {
  // @ts-ignore
  const result: Record<WalletTxMethod, boolean> = {
    [WalletTxMethod.DailyBonus]: false,
    [WalletTxMethod.DailyCashBack]: false,
    [WalletTxMethod.InstantCashBack]: false,
    [WalletTxMethod.MonthlyCashBack]: false,
    [WalletTxMethod.WeeklyCashBack]: false,
    [WalletTxMethod.ReferralCommission]: false,
    [WalletTxMethod.ExternalBonus]: false,
  };
  if (!data) return result;
  Object.keys(result).forEach((k) => {
    const v = data[k as keyof typeof data];
    if (!v) return;
    if (
      [
        WalletTxMethod.DailyCashBack,
        WalletTxMethod.InstantCashBack,
        WalletTxMethod.WeeklyCashBack,
        WalletTxMethod.MonthlyCashBack,
      ].includes(k as WalletTxMethod)
    ) {
      let isCashbackClaimable = false;
      if (v.initialStatus === RewardStatus.CLAIMABLE && bnOrZero(v.claimPoints).gt(0)) {
        isCashbackClaimable = true;
      }
      if (v.minClaimPoints && bnOrZero(v.claimPoints).lt(v.minClaimPoints)) {
        isCashbackClaimable = false;
      }
      result[k as WalletTxMethod] = isCashbackClaimable;
    }
    if (k === WalletTxMethod.DailyBonus) {
      result[k] = !((v.remainingSeconds ?? 0) > 0);
    }
  });

  return result;
};
