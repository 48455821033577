import { CloseIframeDialog, OpenIframeDialog, UpdateIframeSrcDoc, HideBannerWhenOpenGame, UpdateIframeUrl } from "./IframeDialog";

export type OpenIframeDialogOptions = {
  withCloseButton?: boolean;
  withHeader?: boolean;
  closeConfirmMessage?: string;
  withConfirmCloseDialog?: boolean;
  withLogo?: boolean;
  withAppInstallerOffset?: boolean;
  allowLandscapeMode?: boolean;
  closeOnBack?: boolean;
};
export { default } from "./IframeDialog";
export function openIframeDialog(
  {
    url,
    title,
    id,
  }: {
    url?: string | null;
    title: string;
    id?: string;
  },
  options?: OpenIframeDialogOptions,
) {
  window.dispatchEvent(
    new CustomEvent(OpenIframeDialog, {
      detail: {
        url,
        title,
        uniqueId: id,
        options,
      },
    }),
  );
}

export function closeIframeDialog({ id }: { id?: string } = {}) {
  window.dispatchEvent(
    new CustomEvent(CloseIframeDialog, {
      detail: {
        uniqueId: id,
      },
    }),
  );
}

export function updateIframeUrl(url: string) {
  window.dispatchEvent(
    new CustomEvent(UpdateIframeUrl, {
      detail: {
        url,
      },
    }),
  );
}

export function hideDownloadBanner(hide: boolean) {
  window.dispatchEvent(
    new CustomEvent(HideBannerWhenOpenGame, {
      detail: { hide },
    }),
  );
}

export function updateIframeSrcDoc(html: string) {
  window.dispatchEvent(
    new CustomEvent(UpdateIframeSrcDoc, {
      detail: {
        html,
      },
    }),
  );
}
