import { JwtPayload } from "@types";
import moment from "moment";
import logger from "./logger";

export const parseJwt = (token: string): JwtPayload | null => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload) as JwtPayload;
  } catch (e) {
    logger._console.error(e);
  }
  return null;
};

export const isTokenExpired = (token: string): boolean => {
  const parsedToken = parseJwt(token);
  if (!parsedToken) {
    return true;
  }
  return moment.unix(parsedToken.exp).isBefore(moment());
};
