import { IconClose } from "@assets/icons";
import BrandLogo from "@components/data-display/BrandLogo";
import useTypedSelector from "@hooks/useTypedSelector";
import { Dialog, Prompt, cn, delay } from "kz-ui-sdk";
import NoSleep from "nosleep.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OpenIframeDialogOptions, hideDownloadBanner } from ".";

export const OpenIframeDialog = "OPEN_IFRAME_DIALOG";
export const CloseIframeDialog = "CLOSE_IFRAME_DIALOG";
export const UpdateIframeUrl = "UPDATE_IFRAME_URL";
export const UpdateIframeSrcDoc = "UPDATE_IFRAME_SRC_DOC";
export const HideBannerWhenOpenGame = "HIDE_BANNER";

export interface CustomData {
  url?: string | null;
  title: string;
  uniqueId?: string;
  options?: OpenIframeDialogOptions;
}

export interface CustomCloseData {
  uniqueId?: string;
}

export interface CustomUpdateUrlData {
  url?: string;
  html?: string;
}
const noSleep = new NoSleep();

export default function IframeDialog() {
  const [iframeUrl, setIframeUrl] = useState<string | undefined | null>(undefined);
  const [iframeSrcDoc, setIframeSrcDoc] = useState<string>();
  const [iframeTitle, setIframeTitle] = useState("");
  const [iframeOptions, setIframeOptions] = useState<OpenIframeDialogOptions | undefined>(undefined);
  const [isOpenConfirmCloseDrawer, setIsOpenConfirmCloseDrawer] = useState(false);
  const [id, setId] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);

  const closeDialog = useCallback(
    (options?: { popHistory?: boolean }) => {
      const { popHistory = true } = options ?? {};
      setIframeUrl(undefined);
      setIframeSrcDoc(undefined);
      setIframeTitle("");
      setId(undefined);

      hideDownloadBanner(false);
      // if user close by normal close button on header, we should remove the history
      if (popHistory && iframeOptions?.closeOnBack) {
        delay(500).then(() => {
          window.history.back();
        });
      }
    },
    [iframeOptions?.closeOnBack],
  );

  // handle popstate event (back button) to close iframe without navigating back
  useEffect(() => {
    if (!iframeOptions?.closeOnBack) return;
    const handlePopState = (event: PopStateEvent) => {
      if (!!iframeUrl && iframeOptions?.closeOnBack) {
        closeDialog({
          popHistory: false,
        });
        event.preventDefault();
        // to prevent the browser from navigating back, we push a new state without changing the URL
        window.history.pushState(null, "", window.location.pathname);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [closeDialog, iframeUrl, iframeOptions?.closeOnBack]);

  useEffect(() => {
    const handleRemoteOpen = (e: CustomEvent<CustomData>) => {
      setIframeUrl(e.detail.url);
      setIframeTitle(e.detail.title);
      setIframeOptions({
        withCloseButton: false,
        withHeader: true,
        withConfirmCloseDialog: false,
        withLogo: false,
        withAppInstallerOffset: true,
        ...e.detail.options,
      });
      setId(e.detail.uniqueId);
    };
    const handleRemoteClose = (e: CustomEvent<CustomData>) => {
      if (e.detail.uniqueId && e.detail.uniqueId !== id) return;
      noSleep.disable();
      closeDialog();
    };
    const handleUpdateIframeUrl = (e: CustomEvent<CustomUpdateUrlData>) => {
      if (e.detail.url) {
        setIframeUrl(e.detail.url);
      }
    };
    const handleUpdateIframeSrcDoc = (e: CustomEvent<CustomUpdateUrlData>) => {
      if (e.detail.html) {
        setIframeSrcDoc(e.detail.html);
      }
    };
    //
    window.addEventListener(OpenIframeDialog, handleRemoteOpen as EventListener);
    window.addEventListener(CloseIframeDialog, handleRemoteClose as EventListener);
    window.addEventListener(UpdateIframeUrl, handleUpdateIframeUrl as EventListener);
    window.addEventListener(UpdateIframeSrcDoc, handleUpdateIframeSrcDoc as EventListener);

    return () => {
      window.removeEventListener(OpenIframeDialog, handleRemoteOpen as EventListener);
      window.removeEventListener(CloseIframeDialog, handleRemoteClose as EventListener);
      window.removeEventListener(UpdateIframeUrl, handleUpdateIframeUrl as EventListener);
      window.removeEventListener(UpdateIframeSrcDoc, handleUpdateIframeSrcDoc as EventListener);
    };
  }, [closeDialog, id]);

  const withAppInstallerOffset = useMemo(() => {
    return isDisplayAppInstaller && iframeOptions?.withAppInstallerOffset;
  }, [isDisplayAppInstaller, iframeOptions?.withAppInstallerOffset]);

  const isOpen = iframeUrl !== undefined || !!iframeSrcDoc;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        title={
          <div className="flex flex-col items-center justify-center">
            <BrandLogo className="opacity-50" />
          </div>
        }
        classes={{
          "backDrop&": "!pointer-events-none",
          "container&": "bg-none border-0 shadow-none",
          "containerWrapper&": cn("max-w-md left-1/2 -translate-x-1/2", {
            "top-10 h-[calc(100dvh-40px)]": iframeOptions?.withHeader && withAppInstallerOffset,
            "landscape:max-w-full landscape:left-0 landscape:translate-x-0": iframeOptions?.allowLandscapeMode,
            "z-[10000]": !iframeOptions?.withAppInstallerOffset,
          }),
        }}
        className="z-[1030]"
        transition
        zIndex={20}
      >
        {iframeOptions?.withHeader && (
          <div className={cn("absolute left-0 top-0 z-[100] flex h-8 w-full items-center bg-dialog px-1 py-1")}>
            {iframeOptions?.withLogo && (
              <BrandLogo
                size="auto"
                className="py-1"
              />
            )}
            <span className="ml-1 line-clamp-1">{iframeTitle}</span>
            <button
              className="ml-auto mr-1 capitalize underline"
              onClick={() => {
                if (iframeOptions.withConfirmCloseDialog) {
                  setIsOpenConfirmCloseDrawer(true);
                } else {
                  closeDialog();
                }
              }}
            >
              {t("close")}
            </button>
          </div>
        )}
        <div
          className={cn("fixed left-0 top-0 z-[100] h-full w-full border-0", {
            "!top-8": iframeOptions?.withHeader,
            "!h-[calc(100dvh-32px)]": !withAppInstallerOffset,
            "!h-[calc(100dvh-72px)]": withAppInstallerOffset,
            "pointer-events-none": isOpenConfirmCloseDrawer,
          })}
        >
          {(iframeUrl || iframeSrcDoc) && (
            <iframe
              src={iframeUrl ?? undefined}
              srcDoc={iframeSrcDoc}
              title={iframeTitle}
              className="h-full w-full"
            />
          )}
        </div>
      </Dialog>
      {iframeOptions?.withCloseButton && (
        <IconClose
          width={32}
          height={32}
          onClick={(e) => {
            e.preventDefault();
            closeDialog();
          }}
        />
      )}

      <Prompt
        open={isOpenConfirmCloseDrawer}
        onClose={() => setIsOpenConfirmCloseDrawer(false)}
        title={t("confirm")}
        content={t(iframeOptions?.closeConfirmMessage as string)}
        onCancel={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpenConfirmCloseDrawer(false);
        }}
        onConfirm={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpenConfirmCloseDrawer(false);
          closeDialog();
        }}
        messages={{
          confirm: t("confirm"),
          cancel: t("close"),
        }}
        zIndex={20}
      />
    </>
  );
}
